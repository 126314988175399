import { useState } from 'react';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { Redirect } from 'react-router-dom';

import { getInfo } from '../utils/apiCalls';

const Login = () => {
	const [auth] = useLocalStorage('auth');

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [domain, setDomain] = useState('');
	const [error, setError] = useState('');

	const handleLogin = async () => {
		const success = await getInfo({ username, password, domain });

		if (!success) {
			setError('there was an issue logging in');
		} else {
			writeStorage('auth', { username, password, domain });
		}
	};

	const updateField = (e, setter) => {
		setError('');
		setter(e.target.value);
	};

	if (auth) {
		return <Redirect to="/" />;
	}

	return (
		<div>
			<h1>login</h1>
			<div className="loginForm">
				<p className="loginFieldLabel">username</p>
				<input
					type="text"
					className="loginField"
					placeholder="username"
					onChange={(e) => updateField(e, setUsername)}
				/>
				<p className="loginFieldLabel">password</p>
				<input
					type="password"
					className="loginField"
					placeholder="password"
					onChange={(e) => updateField(e, setPassword)}
				/>
				<p className="loginFieldLabel">server</p>
				<input
					type="text"
					className="loginField"
					placeholder="server"
					onChange={(e) => updateField(e, setDomain)}
				/>
				<button onClick={handleLogin} className="loginBtn">
					login
				</button>
				{error && <p style={{ color: 'red' }}>{error}</p>}
			</div>
		</div>
	);
};

export default Login;
