import { Redirect } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';

const ProtectedRoute = ({ Component, ...props }) => {
	const [auth] = useLocalStorage('auth');

	return auth ? <Component {...props} /> : <Redirect to="/login" />;
};

export default ProtectedRoute;
