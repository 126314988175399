import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';

import ProtectedRoute from './components/ProtectedRoute';

import Categories from './pages/Categories';
import Category from './pages/Category';
import Login from './pages/Login';

import './App.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function App() {
	return (
		<BrowserRouter>
			<ScrollMemory />
			<div className="App">
				<Switch>
					<Route
						exact
						path="/"
						component={(props) => (
							<ProtectedRoute Component={Categories} {...props} />
						)}
					/>
					<Route exact path="/login" component={Login} />

					<Route
						exact
						path="/category/:categoryId"
						component={(props) => (
							<ProtectedRoute Component={Category} {...props} />
						)}
					/>
					<Route path="*" component={() => <Redirect to="/" />} />
				</Switch>
			</div>
		</BrowserRouter>
	);
}

export default App;
