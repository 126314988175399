import { Icon } from 'react-icons-kit';
import { signOut } from 'react-icons-kit/fa/signOut';
import { deleteFromStorage } from '@rehooks/local-storage';

const Logout = () => {
	return (
		<Icon
			icon={signOut}
			size={32}
			onClick={() => deleteFromStorage('auth')}
			className="logoutBtn"
		/>
	);
};

export default Logout;
