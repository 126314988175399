import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { useLocalStorage } from '@rehooks/local-storage';

import Logout from '../components/Logout';
import { getCategories } from '../utils/apiCalls';
import { useWindowSize } from '../utils/useWindowSize';

const Categories = () => {
	const headerRef = useRef(null);
	const [categories, setCategories] = useState([]);
	const [auth] = useLocalStorage('auth');
	const windowSize = useWindowSize();
	const headerHeight = headerRef.current ? headerRef.current.clientHeight : 0;

	useEffect(() => {
		getCategories(setCategories, auth);
	}, [auth]);

	return (
		<div>
			<h1 ref={headerRef}>Categories</h1>
			<Logout />
			<div
				className="categoriesContainer"
				style={{ height: `${windowSize.height - headerHeight - 50}px` }}
			>
				{categories.length === 0 && (
					<div style={{ margin: 'auto' }}>
						<TailSpin color="#fff" height={180} width={180} />
					</div>
				)}
				{categories.map(
					({ category_id: categoryId, category_name: categoryName }) => (
						<Link
							to={`/category/${categoryId}?categoryName=${categoryName}`}
							key={categoryId}
							className="categoryOption"
						>
							<p>{categoryName}</p>
						</Link>
					)
				)}
			</div>
		</div>
	);
};

export default Categories;
