import { useState, useEffect, useRef } from 'react';
import qs from 'query-string';
import ReactPlayer from 'react-player';
import Fuse from 'fuse.js';
import { Icon } from 'react-icons-kit';
import { caretLeft } from 'react-icons-kit/fa/caretLeft';
import { caretRight } from 'react-icons-kit/fa/caretRight';
import { TailSpin } from 'react-loader-spinner';
import { useLocalStorage } from '@rehooks/local-storage';

import Logout from '../components/Logout';
import BackBtn from '../components/BackBtn';
import { getCategory, getStreamSrc } from '../utils/apiCalls';
import { useWindowSize } from '../utils/useWindowSize';

const Category = ({ match, location }) => {
	const headerRef = useRef(null);
	const searchRef = useRef(null);
	const [auth] = useLocalStorage('auth');
	const [streams, setStreams] = useState([]);
	const [streamIndex, setStreamIndex] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const { categoryName } = qs.parse(location.search);
	const { categoryId } = match.params;
	const windowSize = useWindowSize();

	const displacedHeight =
		(headerRef.current ? headerRef.current.clientHeight : 0) +
		(searchRef.current ? searchRef.current.clientHeight : 0);

	const stream = streamIndex !== null ? streams[streamIndex] : null;

	const fuse = new Fuse(streams, {
		keys: ['name'],
		threshold: 0.3,
	});

	const searchResults = searchTerm
		? fuse.search(searchTerm).map(({ item }) => item)
		: streams;

	const changeChannel = (goBack) => {
		if (goBack) {
			if (streamIndex !== 0) {
				setStreamIndex(streamIndex - 1);
			}
		} else {
			if (streamIndex < streams.length - 1) {
				setStreamIndex(streamIndex + 1);
			}
		}
	};

	useEffect(() => {
		getCategory(categoryId, setStreams, auth);
	}, [categoryId, auth]);

	return (
		<div>
			<BackBtn />
			<Logout />
			<h1 ref={headerRef}>{categoryName}</h1>
			<div
				className="categoryContainer"
				style={{
					height: `${windowSize.height - displacedHeight}px`,
				}}
			>
				<div
					style={{ flex: '0 0 20rem', maxWidth: '30rem', minWidth: '20rem' }}
				>
					<div className="searchWrapper" ref={searchRef}>
						<input
							type="text"
							placeholder="search"
							onChange={(e) => setSearchTerm(e.target.value)}
							className="search-input"
						/>
					</div>

					<div
						className="streamsList"
						style={{
							height: `calc(100% - ${
								searchRef.current ? searchRef.current.clientHeight : 0
							}px)`,
						}}
					>
						{streams.length === 0 ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									height: '80%',
									alignItems: 'center',
								}}
							>
								<TailSpin color="#fff" height={80} width={80} />
							</div>
						) : (
							searchResults.map(
								(
									{ name: streamName, stream_id: streamId, position },
									index
								) => {
									return (
										<span
											key={streamId}
											onClick={() => setStreamIndex(position)}
										>
											<p
												className="streamOption"
												style={{
													color: streamIndex === position ? '#00eb00' : 'white',
													marginBottom:
														index === searchResults.length - 1
															? '2rem'
															: '0rem',
												}}
											>
												{streamName}
											</p>
										</span>
									);
								}
							)
						)}
					</div>
				</div>

				<div className="videoWrapper">
					<div className="streamInfo">
						<h2 style={{ textAlign: 'center' }}>
							{stream ? stream.name : '...'}
						</h2>
						<div className="streamControls">
							<p className="prevBtn" onClick={() => changeChannel(true)}>
								<Icon icon={caretLeft} /> previous
							</p>
							<p className="nextBtn" onClick={() => changeChannel(false)}>
								next <Icon icon={caretRight} />
							</p>
						</div>
					</div>

					<ReactPlayer
						url={
							stream
								? getStreamSrc(stream.stream_id, auth)
								: 'https://c.tenor.com/Tu0MCmJ4TJUAAAAC/load-loading.gif'
						}
						playing
						controls
						pip
						playsinline
						width={'75%'}
						height={'auto'}
						style={{ margin: '4% auto', flex: '4', display: 'flex' }}
						onPlay={(e) =>
							console.log(`PLAYING: ${getStreamSrc(stream.stream_id, auth)}`)
						}
						onError={(e) =>
							console.log(
								`ERROR PLAYING: ${getStreamSrc(stream.stream_id, auth)}`
							)
						}
						config={{
							file: {
								forceHLS: true,
							},
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Category;
