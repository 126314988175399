import axios from 'axios';
import sortBy from 'lodash.sortby';

export const getInfo = async ({ username, password, domain }) => {
	try {
		const { data, status } = await axios.get(
			`${domain}/player_api.php?username=${username}&password=${password}`
		);
		return data && status === 200;
	} catch (e) {
		console.log(e);
		return false;
	}
};

export const getCategories = async (
	callback,
	{ username, password, domain }
) => {
	const creds = `username=${username}&password=${password}`;

	const { data } = await axios.get(
		`${domain}/player_api.php?${creds}&action=get_live_categories`
	);

	return callback(data);
};

export const getCategory = async (
	id,
	callback,
	{ username, password, domain }
) => {
	const creds = `username=${username}&password=${password}`;

	const { data } = await axios.get(
		`${domain}/player_api.php?${creds}&action=get_live_streams&category_id=${id}`
	);

	const sorted = sortBy(data, (d) => d.name).map((d, i) => ({
		...d,
		position: i,
	}));

	return callback(sorted);
};

export const getStreamSrc = (id, auth) => {
	if (auth) {
		const { username, password, domain } = auth;
		return `${domain}/live/${username}/${password}/${id}.m3u8`;
	}

	return '';
};
