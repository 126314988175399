import { Link } from 'react-router-dom';
import { Icon } from 'react-icons-kit';
import { angleLeft } from 'react-icons-kit/fa/angleLeft';

const BackBtn = () => {
	return (
		<Link
			to="/"
			style={{
				position: 'absolute',
				top: '0',
				paddingTop: '.25rem',
			}}
			className="backToCategories"
		>
			<p className="backLink">
				<Icon
					icon={angleLeft}
					size={32}
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: '.3rem',
					}}
				/>
				Categories
			</p>
		</Link>
	);
};

export default BackBtn;
